export const constants = {
  auth: {
    api: {
      login: 'api/auth/login'
    },
    jwt: {
      secret: 'the khaota echo secret key',
      clientId: ''
    },
    localStorage: {
      currentUser: 'cu',
      tokenType: 'tt',
      refreshToken: 'rt',
      profile: 'profile',
      expired: 'exp'
    },
    routes: {
      login: 'login',
      logout: 'logout',
      register: 'register',
      resetPassword: 'reset-password',
      forgetPassword: 'forgot-password'
    }
  }
};

