import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

const routes: Routes = [
  {
    path: "",
    redirectTo: "auth/login",
    pathMatch: "full",
  },
  {
    path: "auth",
    loadChildren:
      "./modules/authentication/authentication.module#AuthenticationModule",
  },
  {
    path: "user-account",
    loadChildren:
      "./modules/user-account/user-account.module#UserAccountModule",
  },
  {
    path: "setting",
    loadChildren: "./modules/setting/setting.module#SettingModule",
  },
  {
    path: "console",
    loadChildren: "./modules/console/console.module#ConsoleModule",
  },
  {
    path: "shop/:shopCode",
    loadChildren: "./modules/shop/shop.module#ShopModule",
  },
  {
    path: "shop/:shopCode/product",
    loadChildren: "./modules/product/product.module#ProductModule",
  },
  {
    path: "shop/:shopCode/service",
    loadChildren: "./modules/service/service.module#ServiceModule",
  },
  {
    path: "shop/:shopCode/booking",
    loadChildren: "./modules/booking/booking.module#BookingModule",
  },
  {
    path: "shop/:shopCode/stock",
    loadChildren: "./modules/stock/stock.module#StockModule",
  },
  {
    path: "shop/:shopCode/order",
    loadChildren: "./modules/order/order.module#OrderModule",
  },
  {
    path: "shop/:shopCode/cashier",
    loadChildren: "./modules/cashier/cashier.module#CashierModule",
  },
  {
    path: "shop/:shopCode/promotion",
    loadChildren: "./modules/promotion/promotion.module#PromotionModule",
  },
  {
    path: "shop/:shopCode/dashboard",
    loadChildren: "./modules/dashboard/dashboard.module#DashboardModule",
  },
  {
    path: "shop/:shopCode/stock-locations",
    loadChildren:
      "./modules/stock-location/stock-location.module#StockLocationModule",
  },
  {
    path: "shop/:shopCode/campaign",
    loadChildren: "./modules/campaign/campaign.module#CampaignModule",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
