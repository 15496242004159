import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../../../environments/environment';

@Injectable()
export class CookieServerService {
  private cookies: Map<string, string>;
  private domain: string;
  private lifeTime: number;
  private path: string;
  private sameSite: 'Lax' | 'Strict';
  private secure: boolean;

  constructor(private readonly cs: CookieService) {
    const cookieConfig = environment.cookieConfig;
    this.domain = environment.domain;
    this.lifeTime = cookieConfig.lifeTime;
    this.path = cookieConfig.path;
    this.sameSite = 'Strict';
    this.secure = cookieConfig.secure;
  }

  check(name: string): boolean {
    return this.cs.check(name);
  }

  get(name: string): string {
    if (this.cookies) {
      return this.cookies.get(name);
    }
    return this.cs.get(name);
  }

  getAll(): {} {
    const cookies: {} = {};
    if (this.cookies) {
      this.cookies.forEach((value, key) => {
        cookies[key] = value;
      });
    }
    return cookies;
  }

  set(name: string, value: string, expires = this.lifeTime, path = this.path): void {
    this.cs.set(
      name,
      value,
      expires,
    );
  }

  delete(name: string, path = this.path): void {
    this.cs.delete(name);
  }

  deleteAll(): void {
    this.cs.deleteAll();
  }
}
