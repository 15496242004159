import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';

import { AuthenticationService } from '../services/authentication.service';
import { catchError, switchMap, take, filter, finalize, tap } from 'rxjs/operators';
import { LoadingService } from '../services/loading.service';
import { LoadingState } from '../enums/loading.enum';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  private isRefreshing = true;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private authService: AuthenticationService,
    private loadingService: LoadingService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.loadingService.state.next({
      url: request.url,
      state: LoadingState.start
    });
    // add authorization header with jwt token if available
    return next.handle(this.setAuthHeader(request)).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          return this.handleUnAuthError(request, next);
        } else {
          return Observable.throw(error);
        }
      }),
      finalize(() => {
        this.loadingService.state.next({
          url: request.url,
          state: LoadingState.end
        });
      })
    );
  }

  private setAuthHeader(request: HttpRequest<any>) {
    const currentUser = this.authService.getCurrentUser;

    if (currentUser) {
      return request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentUser}`
        }
      });
    }

    return request;
  }

  private handleUnAuthError(request: HttpRequest<any>, next: HttpHandler) {
    return this.authService.refreshToken().pipe(
      switchMap(jwt => {
        return next.handle(this.setAuthHeader(request));
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.authService.logout();
        } else {
          return Observable.throw(error);
        }
      }),
    );
  }
}
