import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

const WIKI_URL = 'https://en.wikipedia.org/w/api.php';
const PARAMS = new HttpParams({
  fromObject: {
    action: 'opensearch',
    format: 'json',
    origin: '*'
  }
});

@Injectable({
  providedIn: 'root'
})
export class AutocompleteService {
  constructor(private readonly http: HttpClient) {}

  filter(url: string, keyword: string) {
    if (keyword === '' || url === '') {
      return of([]);
    }

    const reqUrl = `${url}&keyword=${encodeURIComponent(keyword)}`;

    return this.http.get(reqUrl).pipe(map((resp: any) => resp));
  }

  search(term: string) {
    if (term === '') {
      return of([]);
    }

    return this.http
      .get(WIKI_URL, {params: PARAMS.set('search', term)}).pipe(
        map(response => response[1])
      );
  }
}
