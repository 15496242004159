export const environment = {
  production: false,
  localDatabaseName: 'KhaotaShop',
  localDatabaseVersion: 1,
  // apiUrl: 'https://localhost:5003/api',
  apiUrl: 'http://dev.khaota.com:5002/api',
  noImgUrl: 'assets/images/no image 512x512.png',
  domain: 'khoata.com',
  jwt: {
    endpoint: 'https://localhost:5001/api',
    clientId: 'FB54F3C5992B96D001BB16E8E92D968D',
  },
  cookieConfig: {
    lifeTime: 30,
    path: null,
    secure: true,
    sameSite: 'Lax'
  },
};
