import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IHttpState } from '../interfaces/loading-state.interface';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  public state = new BehaviorSubject<IHttpState>({} as IHttpState);

  constructor() { }
}
