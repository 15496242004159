import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

import { constants } from '../constants/constants';

import { environment } from 'src/environments/environment';
import { CookieServerService } from './cookie-server.service';
import { LoginForm } from '../../authentication/models';
import { HttpLoginResponse } from '../models/http-login-response.model';

@Injectable()
export class AuthenticationService {
  constructor(
    private storage: CookieServerService,
    private http: HttpClient
  ) { }

  public get getCurrentUser(): string {
    return this.storage.check(constants.auth.localStorage.currentUser) ? this.storage.get(constants.auth.localStorage.currentUser) : '';
  }

  public get hasActiveUser(): boolean {
    return this.storage.check(constants.auth.localStorage.currentUser);
  }

  register(payload: any) {
    payload.username = payload.email;

    const req = this.http.post<any>(`${environment.apiUrl}/account/register`, payload)
      .pipe(map(user => {
        return user;
      }));

    return req;
  }

  reqResetPass(email: string) {
    const req = this.http.post<any>(`${environment.apiUrl}/account/reqResetPass`, {email})
      .pipe(map(user => {
        this.setAuthCookie(user);
        return user;
      }));

    return req;
  }

  changePassword(
    token: string,
    email: string,
    oldPassword: string,
    newPassword: string,
    confirmPassword: string
  ) {
    const payload = { token, email, oldPassword, newPassword, confirmPassword };
    const req = this.http.post<any>(`${environment.apiUrl}/account/changePass`, payload)
      .pipe(map(user => {
        this.setAuthCookie(user);
        return user;
      }));

    return req;
  }

  login(loginForm: LoginForm) {
    return this.http.post<any>(`${environment.apiUrl}/account/login`, loginForm)
      .pipe(
        tap((res: HttpLoginResponse) => {
          this.setAuthCookie(res);
        }),
      );
  }

  refreshToken() {
    return this.http.post<any>(`${environment.apiUrl}/account/refreshToken`, this.getRefreshToken())
      .pipe(map(user => {
        this.setAuthCookie(user);
        return user;
      })
    );
  }

  profile() {
    const requestLogin = this.http.get<any>(`${environment.apiUrl}/account/profile`)
      .pipe(map(profile => {
        return profile;
      }));
    return requestLogin;
  }

  logout() {
    console.log('logout');
    this.storage.delete(constants.auth.localStorage.currentUser);
    this.storage.delete(constants.auth.localStorage.refreshToken);
    this.storage.delete(constants.auth.localStorage.tokenType);
    this.storage.delete(constants.auth.localStorage.expired);
    console.log(this.storage.get(constants.auth.localStorage.currentUser));
  }

  private setAuthCookie(authRes: HttpLoginResponse): void {
    this.storage.deleteAll();
    this.storage.set(constants.auth.localStorage.currentUser, authRes.access_token);
    this.storage.set(constants.auth.localStorage.refreshToken, authRes.refresh_token);
    this.storage.set(constants.auth.localStorage.tokenType, authRes.token_type);
    this.storage.set(constants.auth.localStorage.expired, authRes.expires_in.toString());
  }

  private getRefreshToken(): any {
    const body = {
      refreshToken: this.storage.get(constants.auth.localStorage.refreshToken),
      clientId: environment.jwt.clientId
    };


    console.log(body);

    return body;
  }
}
